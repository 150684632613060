import * as React from 'react';
import { useHALGet } from 'bb/api/browser';
import { type HalLink } from 'bb/common/types';
import { AmountPerMonth, useTranslation, Text, Header } from 'bb/i18n';
import { type ProductResponse } from 'bb/subscription/types';
import { Card, Col, Spinner, Stack } from 'bb/ui';
import { isNotEqualNum } from 'bb/utils';
import css from './profiles.module.scss';

type ProfilesInfoProps = {
    link: HalLink;
};
const ProfilePricesPerSubscription = ({
    products
}: {
    products: ProductResponse[];
}) => {
    const compareProfilePrice = products[0]!.profileprice;

    const hasProfilePriceDifference = products?.find((product) =>
        isNotEqualNum(compareProfilePrice, product.profileprice)
    );

    return hasProfilePriceDifference && products ? (
        <ul>
            {products?.map((product: ProductResponse) => (
                <li key={product.displayname}>
                    <Text
                        size="medium"
                        t="myAccount:pricePerProfileForSubscription"
                        as="span"
                    >
                        <AmountPerMonth amount={product?.profileprice} />
                        {product?.displayname}
                    </Text>
                </li>
            ))}
        </ul>
    ) : (
        <Text size="medium" t="myAccount:pricePerProfile" as="span">
            <AmountPerMonth amount={products[0]?.profileprice} />
        </Text>
    );
};

export const ProfilesInfo: React.FC<ProfilesInfoProps> = ({ link }) => {
    useTranslation(['myAccount']);
    const { data, isWaiting } = useHALGet<ProductResponse[]>(link);

    return (
        <Col className={css.column}>
            <Spinner nested show={isWaiting} size="small">
                <Card withBorder padding="extraSmall" marginBottom="extraSmall">
                    <Header
                        type="secondary"
                        as="h2"
                        size="small"
                        marginBottom="extraSmall"
                        t="myAccount:profilesInformationHeader"
                    />
                    <Stack>
                        <Text
                            as="div"
                            size="medium"
                            t="myAccount:profilesInformationText"
                        >
                            {data && (
                                <ProfilePricesPerSubscription products={data} />
                            )}
                        </Text>
                    </Stack>
                </Card>
            </Spinner>
        </Col>
    );
};
